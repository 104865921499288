nav, footer, video, audio, object, embed { 
display:none; 
}
.breadcrumb-path,
.downloadList,
.menu-lang,
.row-journal-filter{
	display:none;
}

table{
	tbody{
		tr{
			td{
				border-bottom: 1px solid #ccc;
				border-top: 1px solid #ccc;
			}
		}
	}
}
.logo-interno{
	height:0;
	display:none;
}

.journalAddress .col-md-10,.journalContent .issueIndex{
    width:100%
}
a:link:after,a:visited:after{
    display:none;
    content:""!important
}
.articleAction,.collectionListSearch,.highlight,.issueNav,.journalLinks,.journalMenu,.language,.levelMenu,.mainNav,.newSearchField,.searchActions,.searchFilters,.searchOptions,.shLink,.share{
    display:none!important
}
.searchForm .resultBlock{
    margin-top:20px;
    padding-top:20px;
    border-top:1px dotted #ccc
}
.searchForm .results .item{
    padding:20px;
    border-bottom:1px dotted #ccc;
    page-break-inside:avoid
}
.searchForm .results .item:last-child{
    border-bottom:0
}
.searchForm .results input.checkbox+label.checkbox{
    background:0 0
}
.searchForm .results .versions{
    padding:7px 0!important;
    background:0 0
}
.searchForm .results .metadata{
    padding:7px 0!important
}
.searchForm .results .metadata .col-md-9{
    padding:0
}
.searchForm .searchExpression{
    border-bottom:1px dotted #ccc;
    margin:-20px -15px 0;
    padding:10px 20px;
    background:#fff;
    font-family:Arial;
    color:#333
}
.brandLogo,.journalContent .issueList table td,.journalContent .issueList table th{
    border-bottom:1px solid #ddd
}
.searchForm .searchExpression span{
    display:block;
    font-size:.8em
}
.article .copyLink,.article .fig,.article .glyphFloatMenu,.article .outlineFadeLink,.article .table,.collectionListTable .actions,.collectionListTable .download,.colophon a,.journalContent .issueData{
    display:none
}
.colophon .container{
    border-top:1px dotted #ccc;
    background:0 0
}
.journalContent .issueData{
    margin-top:20px
}
.brandLogo{
    padding:0 0 20px
}
.brandLogo .col-md-offset-1{
    margin:0
}
.journalContent{
    padding:0;
    margin-top:30px
}
.journalContent .issueList{
    padding:0
}
.journalContent .issueList table td a,.journalContent .issueList table th a{
    border:1px solid #d0d0d0
}
.collectionListTable table td,.collectionListTable table th{
    border-bottom:1px solid #ddd
}
.collectionLicense span{
    width:80%
}
.brandLogo{
    width:100%
}